import React from 'react';
import ReactDOM from 'react-dom';
import Video from './components/Video';
import styled from 'styled-components';


ReactDOM.render(
  <div>
    <Video />
  </div>,
  document.getElementById('root')
);
